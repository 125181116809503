import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function BasicTextFields(textId,textLabel) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id={textId} label={textLabel} variant="outlined"  onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          console.log("stop default");
          //TODO Fix this so it automatically changes the name
        }
      }}/>
    </Box>
  );
}