// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// TODO: Use env files
const firebaseConfig = {
    apiKey: "AIzaSyCWiMH_ZjDUMZa2S2L1xSEafNfspEDeKZ0",
    authDomain: "bwlquestionaire.firebaseapp.com",
    projectId: "bwlquestionaire",
    storageBucket: "bwlquestionaire.appspot.com",
    messagingSenderId: "277190408539",
    appId: "1:277190408539:web:a58de2732a664cd513027e",
    measurementId: "G-7BVG38QS3W"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)