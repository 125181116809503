import './App.css';
import React, {useEffect, useState} from "react";
import {collection, getDocs, addDoc,Timestamp} from "firebase/firestore";
import {db} from "./fireb";
import Header from './components/title';
import BasicTextFields from "./components/common/form";

const App = () => {
  //read
  const [users, setUsers] = useState([]);
  const usersCollectionRef = collection(db, "users");
  //write consts
  // const [newName, setNewName] = useState("");
  // const [newID, setNewID] = useState("");
  let namechecknum = 0;
  let tc = 0;
  let arrHead = [];	// array for header.
  arrHead = ['Date', 'Train/Comp', 'BW', 'Lift', 'Load', 'Reps', 'Sets'];
  //writing the data input sheet
  let date = new Date();
  let current_date = date.toISOString().substring(0, 10);
  // document.getElementById('weight_date').value = current_date;
  let exercise_count_number = 1; // Global Variable for Name

  //Array of Exercises and lists
  const exercise_list = ["Snatch", "Clean & Jerk", "Total", "Power Snatch" ,"Hang Snatch", "Block Snatch", "Snatch Deadlift",
    "Clean", "Hang Clean", "Power Clean" ,"Block Clean", "Clean Deadlift", "Power Jerk" ,"Jerk from Rack", "Jerk from Block",
      "Clean Front Squat Jerk", "Push Press", "Back Squat", "Front Squat", "Strict Row", "Strict Press",
    "Trunk Hold", "Back Hold", "Side Hold"];

  const number_list = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20" ];

  const comp_list = [ "Training Load" , "Training Max","Competition Performance"];

  useEffect(() => {
    //table
    if (tc !== 1) {
      tc = 1;
      let numTable = document.createElement('table');
      numTable.setAttribute('id', 'numTable');

      let tr = numTable.insertRow(-1);
      for (let h = 0; h < arrHead.length; h++) {
        let th = document.createElement('th'); // create table headers
        th.innerHTML = arrHead[h];
        tr.appendChild(th);
      }
      const getUsers = async () => {
        const data = await getDocs(usersCollectionRef);
        setUsers(data.docs.map((doc)=> ({...doc.data(), id:doc.id })));
      };
      getUsers();
      let div = document.getElementById('cont');
      div.appendChild(numTable);  // add the TABLE to the container.
    }


  }, []);

  const digits_only = string => [...string].every(c => '0123456789.'.includes(c));

  // https://www.encodedna.com/javascript/practice-ground/default.htm?pg=dynamically_add_remove_table_in_javascript
  function textBoxTable(){
    let numTab = document.getElementById('numTable');
    let rowCnt = numTab.rows.length;   // table row count.
    let tr = numTab.insertRow(rowCnt); // the table row.

    //create the Select Tab
    let exSelect = document.createElement("select");
    let setsSelect = document.createElement("select");
    let repsSelect = document.createElement("select");
    let compSelect = document.createElement("select");
    for (let i = 0; i < exercise_list.length; i++) {
      // TODO need to edit this so it does it at the start and then stores it as an array
      const option = document.createElement("option");
      option.value = exercise_list[i];
      option.text = exercise_list[i];
      exSelect.appendChild(option);
    }
    for (let i = 0; i < number_list.length; i++) {
      // TODO need to edit this so it does it at the start and then stores it as an array
      const option = document.createElement("option");
      option.value = number_list[i];
      option.text = number_list[i];
      setsSelect.appendChild(option);
    }
    for (let i = 0; i < number_list.length; i++) {
      // TODO need to edit this so it does it at the start and then stores it as an array
      const option = document.createElement("option");
      option.value = number_list[i];
      option.text = number_list[i];
      repsSelect.appendChild(option);
    }
    for (let i = 0; i < comp_list.length; i++) {
      // TODO need to edit this so it does it at the start and then stores it as an array
      const option = document.createElement("option");
      option.value = comp_list[i];
      option.text = comp_list[i];
      compSelect.appendChild(option);
    }

    //default bw
    let defaultbwid = document.getElementById("bwdefaultid");
    let bwValue = defaultbwid.value.trim();
    console.log(bwValue)
    if (!digits_only(bwValue)){
      return alert("Please input a number in the BW section");
    }

    for (let c = 0; c < arrHead.length; c++) {

      let td = document.createElement('td'); // table definition.
      td = tr.insertCell(c);
      if (c===0){
        let ele = document.createElement('input');
        ele.setAttribute('type', 'date');
        ele.setAttribute('id', "date_" + exercise_count_number);
        ele.setAttribute('value', '');

        td.appendChild(ele);
      }
      else if (c===1){
        compSelect.setAttribute('id', 'comp_'+ exercise_count_number );
        td.appendChild(compSelect);
      }
      else if (c===2){
        let ele = document.createElement('input');
        ele.setAttribute('type', 'number');
        ele.setAttribute('min', '0');
        ele.setAttribute('inputmode', 'numeric');
        ele.setAttribute('pattern', "[0-9]*")
        ele.setAttribute('id', "bw_" + exercise_count_number);
        ele.setAttribute('value', bwValue);
        td.appendChild(ele);
      }
      else if (c===3){
        exSelect.setAttribute('id', 'exercise_'+ exercise_count_number );
        td.appendChild(exSelect);
      }
      else if (c===4){
        let ele = document.createElement('input');
        ele.setAttribute('type', 'number');
        ele.setAttribute('id', "load_" + exercise_count_number);
        ele.setAttribute('value', '');
        td.appendChild(ele);
      }
      else if (c===5){
        let ele = document.createElement('input');
        ele.setAttribute('type', 'number');
        repsSelect.setAttribute('id', 'reps_'+ exercise_count_number );
        td.appendChild(repsSelect);
      }
      else if (c===6){
        let ele = document.createElement('input');
        ele.setAttribute('type', 'number');
        setsSelect.setAttribute('id', 'sets_'+ exercise_count_number );
        td.appendChild(setsSelect);
      }
    }
    exercise_count_number++;
  }

  // submit weights
  // TODO Change the document get element by id to boolean also just change it to just sets
  const submit_weights = async() => {
    //assigment of varaibles
    let nameid = document.getElementById("nameid");
    let nameValue = nameid.value.trim();
    nameValue = nameValue.toLowerCase();
    let pattern = /(\.co\.uk|\.com)$/i;
    let result = pattern.test(nameValue);
    console.log( !nameValue.includes("@"))
    console.log(!result)
    if (!nameValue.includes("@") || !result){
      return alert("You must put an email address in");
    }
    // alert(nameid.value + excercise_count_number)
    try {
      const athleteCollectionRef = collection(db, nameValue);
      for (let i = 1; i < exercise_count_number; i++) {
        // initial the vars
        let date_id = document.getElementById("date_" + i);
        console.log(Timestamp.fromDate(new Date(date_id.value)));
        let comp_id = document.getElementById("comp_" + i);
        let bw_id = document.getElementById("bw_" + i);
        let exercise_id = document.getElementById("exercise_" + i);
        let load_id = document.getElementById("load_" + i);
        let reps_id = document.getElementById("reps_" + i);
        let sets_id = document.getElementById("sets_" + i);
        if (bw_id.value == null || bw_id.value === ""){
          return alert("One of BW fields is empty, Put your weight category that you compete in if you are unsure!");
        }
        if (load_id.value == null || load_id.value === ""){
          return alert("One of the Load Fields are empty. Please fill it in before continuing!");
        }

        await addDoc(athleteCollectionRef, {
          date: Timestamp.fromDate(new Date(date_id.value)),
          comp: comp_id.value, bw: parseFloat(bw_id.value),
          exercise: exercise_id.value, load: parseFloat(load_id.value),
          reps: parseFloat(reps_id.value), sets: parseFloat(sets_id.value)
        });
      }
      // if it is succesful
      alert("Congrats you have successfully uploaded your training data")
      window.location.reload();
    }catch (error){
      alert("Make sure all values are entered in :)")
    }
  }

  return <div className="App">
    <Header />
    {BasicTextFields("nameid","Email")}
    {BasicTextFields("bwdefaultid", "Default BW")}
    {/*<button onClick={nameCheck}> Check Name </button>*/}
    <div id = "namecheck"></div>
    <div className="two" >
      <button onClick={textBoxTable}  >Add Exercise</button>
    </div>
    <div id="cont"></div>

    <div id="three"></div>
    <button type="submit" value="submit" onClick={submit_weights}> Submit</button>
    {/*write*/}

  </div>

}
export default App;
